<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
//import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiRequest } from "@/helpers/api-call";
import { format } from "date-fns";
import router from "@/router/index.js";
import Vue from "vue";

/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  data() {
    return {
      title: "Liste des vehicules",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "liste",
          active: true,
        },
      ],
      idUser: 0,
      dataUser: [],
      dataUserD: [],
      nbreUser: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,

      ordersData: [],
      ordersDataOff: [],
      fields: [
        { key: "marque", sortable: true, label: "Marque" },
        { key: "model", sortable: true, label: "Modèle" },
        { key: "immatri", label: "Immat." },
        { key: "type", sortable: true, label: "Type" },
        { key: "statut", sortable: true, label: "Statut" },
        { key: "motorisation", sortable: true, label: "Moteur" },
        { key: "vidange", sortable: true, label: "Km av. vidange" },
        { key: "date", sortable: true, label: "Créé le" },
        { key: "action", label: "Actions" },
      ],
      showDisable: false,
      showDelete: false,
      showEdit: false,
      showSucess: false,
      showEchec: false,
      showSucessEdit: false,
      showEchecEdit: false,
      vehToUse: 0,

      //Model modif
      motorisation: ["ESSENCE", "GASOIL", "ELECTRIQUE", "HYBRIDE"],
      climatisation: ["OUI", "NON"],
      boite: ["AUTO", "MANUELLE"],
      statut: ["DISPO", "LOUER", "PANNE"],
      type_vehicule: ["BERLINE", "BUS", "4X4", "UTILITAIRE"],
      place: {
        nom: "",
        longitude: "",
        latitude: "",
      },
      kmAvantVidange: "",
      anneemiseencirculation: "",
      nbrePlace: "",
      couleur: "",
      lieu: "",
      marque: null,
      model: null,
      face: null,
      interieur: null,
      vueInterieur: null,
      arriere: null,
      typevehiculeSelect: null,
      kilometrageSelect: null,
      nombreplaceSelect: null,
      immatriculation: "",
      numeroAssurance: "",
      assurance: "",
      observations: "",
      motorisationSelect: null,
      climatisationSelect: null,
      boiteSelect: null,
      statutSelect: null,
      prixPublieJournalier: 0,
      prixPublieJournalierHorsVille: 0,
      chauffeurSelected: null,
      chauffeurs: [],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.dataUser.length;
    },
  },
  async mounted() {
    this.init();
  },
  methods: {
    async init() {
      //Chargement des données
      const data = await apiRequest(
        "GET",
        "admin/entreprise/location",
        undefined,
        false
      );
      if (data && data.data && data.data.vehiculeActif) {
        const formattedTable = data.data.vehiculeActif.map((vehicule) => {
          return {
            id: vehicule.id,
            model: vehicule.model,
            marque: vehicule.marque,
            immatri: vehicule.immatriculation,
            type: vehicule.types,
            statut: vehicule.statut,
            motorisation: vehicule.motorisation,
            vidange: vehicule.kmAvantVidange,
            date: new Date(vehicule.dateCreation).toLocaleDateString("fr-FR"),
          };
        });
        this.ordersData = formattedTable;

        const formattedTableOff = data.data.vehiculeInActif.map((vehicule) => {
          return {
            id: vehicule.id,
            model: vehicule.model,
            marque: vehicule.marque,
            immatri: vehicule.immatriculation,
            type: vehicule.types,
            statut: vehicule.statut,
            motorisation: vehicule.motorisation,
            vidange: vehicule.kmAvantVidange,
            date: new Date(vehicule.dateCreation).toLocaleDateString("fr-FR"),
          };
        });
        this.ordersDataOff = formattedTableOff;
      }

      this.totalRows = this.ordersData.length;
      this.nbreUser = this.dataUser.length;
    },
    desactiverVehicule(row) {
      this.vehToUse = row.id;
      //console.log('userToDel:', this.vehToUse)
      this.showDisable = true;
    },
    async desactiveV() {
      this.showDisable = false;
      const data = await apiRequest(
        "POST",
        "admin/entreprise/action-location",
        { idVehicule: this.vehToUse, active: false },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        this.position();
        this.init();
      }
    },
    async activerVehicule(row) {
      this.vehToUse = row.id;
      const data = await apiRequest(
        "POST",
        "admin/entreprise/action-location",
        { idVehicule: this.vehToUse, active: true },
        false
      );
      if (data && data.data) {
        this.position();
        this.init();
      }
    },
    async locationRMO(row) {
      this.vehToUse = row.id;
      const data = await apiRequest(
        "POST",
        "admin/entreprise/action-dispo",
        { idVehicule: this.vehToUse, locationrmo: true },
        false
      );
      if (data && data.data) {
        this.position();
        this.init();
      }
    },
    async locationA(row) {
      this.vehToUse = row.id;
      const data = await apiRequest(
        "POST",
        "admin/entreprise/action-dispo",
        { idVehicule: this.vehToUse, locationrmo: false },
        false
      );
      if (data && data.data) {
        this.position();
        this.init();
      }
    },
    goToDetail(row) {
      console.log("row:", row);
      router.push(`/vehicule/details/${row.id}`);
    },

    //***************** */

    async ModifSubmit() {
      this.showEdit = false;

      const Editdata = await apiRequest(
        "PUT",
        "users/" + this.infoUser.id,
        {
          idUser: this.infoUser.id,
          lastname: this.infoUser.lastname,
          firstname: this.infoUser.firstname,
          tel: this.infoUser.telephone,
          email: this.infoUser.email,
          adresse: this.infoUser.adresse,
          ville: this.infoUser.ville,
          pays: this.infoUser.pays,
          name: this.infoUser.name,
          boitePostal: this.infoUser.boitePostal,
          birthday: this.infoUser.birthday,
          userType: this.userTypeSelect,
          profession: this.infoUser.profession,
          apropos: this.infoUser.apropos,
          permisCategorie: this.infoUser.permisCategorie,
          permisAnnee: this.infoUser.permisNumuero,
          permisNumuero: this.infoUser.permisNumuero,
          personneUrgence: this.infoUser.contactUrgence,
          contactUrgence: this.infoUser.contactUrgence,
        },
        false
      );

      if (Editdata && Editdata.data) {
        this.showSucessEdit = true;
        this.init();
        //console.log(Editdata);
      } else {
        this.showEchecEdit = true;
        this.init();
        //console.log(Editdata);
      }

      console.log(this.infoUser.id);
    },
    EditModal() {
      this.submitted = false;
      this.showEdit = false;
    },
    async editClick(row) {
      this.showEdit = true;
      this.infoUser.id = row.id;

      const dataDetail = await apiRequest(
        "POST",
        "admin/detail-user",
        { id: row.id },
        false
      );

      if (dataDetail && dataDetail.data) {
        //console.log("Detail User", dataDetail.data)
        this.infoUser.lastname = dataDetail.data.personne.lastname;
        this.infoUser.firstname = dataDetail.data.personne.firstname;
        this.infoUser.profession = dataDetail.data.personne.profession;
        (this.infoUser.birthday = format(
          new Date(dataDetail.data.personne.birthday),
          "dd/MM/yyyy hh:mm:ss"
        )),
          (this.infoUser.adresse = dataDetail.data.personne.adresse);
        this.infoUser.ville = dataDetail.data.personne.ville;
        this.infoUser.pays = dataDetail.data.personne.pays;
        this.infoUser.email = dataDetail.data.personne.email;
        this.infoUser.contactUrgence = dataDetail.data.personne.contactUrgence;
        this.infoUser.personneUrgence =
          dataDetail.data.personne.personneUrgence;
        this.infoUser.telephone = dataDetail.data.personne.tel;
        this.infoUser.userTypeSelect = dataDetail.data.personne.userType;
      }

      console.log("type ", this.infoUser.userTypeSelect);
      console.log("row ", row);
    },
    onDeleteVehicule(row) {
      this.vehToUse = row.id;
      this.showDelete = true;
    },
    
    position() {
      Vue.swal({
        position: "center-end",
        icon: "success",
        title: "Mise à jour effectuée",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    //**** Modif ***** */
    async addPhotoEnFace(id) {
      if (this.face) {
        let formData = new FormData();
        formData.append("idVehicule", id);
        formData.append("image", this.face);
        const returnPhotoEnFace = apiRequest(
          "POST",
          "admin/entreprise/location/add-image",
          formData,
          true
        );
        if (returnPhotoEnFace && returnPhotoEnFace.data) {
          console.log("Photo en face:", returnPhotoEnFace);
        }
      }
    },
    async addPhotoInterieur(id) {
      if (this.interieur) {
        let formData = new FormData();
        formData.append("idVehicule", id);
        formData.append("image", this.interieur);
        const returnPhotoInterieur = apiRequest(
          "POST",
          "admin/entreprise/location/add-image",
          formData,
          true
        );
        if (returnPhotoInterieur && returnPhotoInterieur.data) {
          console.log("Photo en face:", returnPhotoInterieur);
        }
      }
    },
    async addPhotoVueInterieur(id) {
      if (this.vueInterieur) {
        let formData = new FormData();
        formData.append("idVehicule", id);
        formData.append("image", this.vueInterieur);
        const returnPhotoVueInterieur = apiRequest(
          "POST",
          "admin/entreprise/location/add-image",
          formData,
          true
        );
        if (returnPhotoVueInterieur && returnPhotoVueInterieur.data) {
          console.log("Photo en face:", returnPhotoVueInterieur);
        }
      }
    },
    async addPhotoArriere(id) {
      if (this.arriere) {
        let formData = new FormData();
        formData.append("idVehicule", id);
        formData.append("image", this.arriere);
        const returnPhotoArriere = apiRequest(
          "POST",
          "admin/entreprise/location/add-image",
          formData,
          true
        );
        if (returnPhotoArriere && returnPhotoArriere.data) {
          console.log("Photo en face:", returnPhotoArriere);
        }
      }
    },

    handleFilevehiculeFace() {
      this.face = this.$refs.face.files[0];
    },
    handleFilevehiculeInterieur() {
      this.interieur = this.$refs.interieur.files[0];
    },
    handleFilevehiculeVue() {
      this.vueInterieur = this.$refs.vueInterieur.files[0];
    },
    handleFilevehiculeArriere() {
      this.arriere = this.$refs.arriere.files[0];
    },

    setPlace: function(place) {
      if (place) {
        this.place.nom = place.formatted_address;
        this.place.latitude = place.geometry.location.lat();
        this.place.longitude = place.geometry.location.lng();
      }
      console.log("this.place:", this.place);
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },

    ModifFileUpload() {
      this.donImage = this.$refs.image.files[0];
    },
    positionAdd() {
      Vue.swal({
        positionAdd: "center-end",
        icon: "success",
        title: "Ajout éffectué avec succès",
        showConfirmButton: false,
        timer: 3000,
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <h4 class="card-title mt-4">
              Nombre total de véhicules : {{ this.totalRows }}
            </h4>
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Liste des véhicules</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6"></div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-3">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    style="cursor: pointer"
                    class="table-centered"
                    :items="ordersData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="goToDetail"
                  >
                    <template v-slot:cell(statut)="row">
                      <div
                        class="py-2 d-flex justify-content-center badge font-size-10"
                        :class="{
                          'badge-soft-success':
                            `${row.value}` === 'DISPO AGENCE' ||
                            `${row.value}` === 'DISPO RMO',
                          'badge-soft-warning': `${row.value}` === 'LOUER',
                          'badge-soft-danger': `${row.value}` === 'PANNE',
                        }"
                      >
                        {{ row.value === 'LOUER' ? 'EN LOCATION' :  row.value }} 
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <a
                        v-if="row.item.statut != 'LOUER'"
                        href="javascript:void(0);"
                        class="text-primary"
                        v-b-tooltip.hover
                        @click="editClick(row.item)"
                        title="Editer"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                    <!--   <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        @click="onDeleteVehicule(row.item)"
                        title="Supprimer"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a> -->
                      <a
                        href="javascript:void(0);"
                        class="text-success"
                        v-b-tooltip.hover
                        @click="desactiverVehicule(row.item)"
                        title="Désactiver"
                      >
                        <i class="mdi mdi-close font-size-18"></i>
                      </a>
                      <a
                        v-if="row.item.statut == 'DISPO AGENCE'"
                        href="javascript:void(0);"
                        class="text-warning"
                        v-b-tooltip.hover
                        @click="locationRMO(row.item)"
                        title="En location sur RMobility"
                      >
                        <i class="mdi mdi-arrow-up font-size-18"></i>
                      </a>
                      <a
                        v-if="row.item.statut == 'DISPO RMO'"
                        href="javascript:void(0);"
                        class="text-warning"
                        v-b-tooltip.hover
                        @click="locationA(row.item)"
                        title="En location agence"
                      >
                        <i class="mdi mdi-arrow-down font-size-18"></i>
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active"
                    >Liste des véhicules désactivées</a
                  >
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6"></div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-3">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    style="cursor: pointer"
                    class="table-centered"
                    :items="ordersDataOff"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="goToDetail"
                  >
                    <template v-slot:cell(statut)="row">
                      <div
                        class="py-2 d-flex justify-content-center badge font-size-10"
                        :class="{
                          'badge-soft-success':
                            `${row.value}` === 'DISPO AGENCE' ||
                            `${row.value}` === 'DISPO RMO',
                          'badge-soft-warning': `${row.value}` === 'LOUER',
                          'badge-soft-danger': `${row.value}` === 'PANNE',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <a
                        href="javascript:void(0);"
                        class="text-success"
                        v-b-tooltip.hover
                        @click="activerVehicule(row.item)"
                        title="Activer"
                      >
                        <i class="mdi mdi-check font-size-18"></i>
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showDelete"
      title="Voulez-vous vraiment supprimer ce véhicule ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDelete = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactiveUser"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showSucessEdit"
      title="Utilisateur modifié avec succès !!!"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="success" @click="showSucessEdit = false"
          >OK</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showDisable"
      title="Voulez-vous vraiment désactiver ce véhicule ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDisable = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactiveV"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showEchecEdit"
      title="La modification a échouée"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchecEdit = false"
          >Reéssayez</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      size="xl"
      v-model="showEdit"
      title="Editer véhicule"
      title-class="font-18"
      hide-footer
    >
      <div class="row">
        <div class="col-lg-6">
          <div class="card">
            <div class="card-body">
              <form enctype="multipart/form-data" @submit.prevent="submitCar">
                <div class="row">
                  <div class="col-md-6">
                    <label for="">Type de véhicule</label>
                    <multiselect
                      v-model="typevehiculeSelect"
                      :options="type_vehicule"
                      :close-on-select="true"
                    ></multiselect>
                  </div>
                  <div class="col-md-6">
                    <label for="">Marque</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="marque"
                      id="marque"
                    />
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-6">
                    <label for="">Modèle</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="model"
                      id="model"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="">Motorisation</label>
                    <multiselect
                      v-model="motorisationSelect"
                      :options="motorisation"
                      :close-on-select="true"
                    ></multiselect>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-6">
                    <label for="">Climatisation</label>
                    <!-- <input type="text" class="form-control" id="" /> -->
                    <multiselect
                      v-model="climatisationSelect"
                      :options="climatisation"
                      :close-on-select="true"
                    ></multiselect>
                  </div>
                  <div class="col-md-6">
                    <label for="boite">Boîte</label>
                    <multiselect
                      v-model="boiteSelect"
                      :options="boite"
                      :close-on-select="true"
                    ></multiselect>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-6">
                    <label for="kilometrage">Kimolétrage au compteur</label>
                    <!-- <multiselect
                    v-model="kilometrageSelect"
                    :options="kilometrage"
                    label="name"
                    track-by="name"
                    :close-on-select="true"
                  ></multiselect> -->
                    <input
                      type="text"
                      class="form-control"
                      v-model="kilometrageSelect"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="kmAvantVidange"
                      >Nombre kilométrage avant vidange</label
                    >
                    <input
                      type="number"
                      class="form-control"
                      v-model="kmAvantVidange"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="card">
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-md-6">
                    <label>Année de mise en circulation</label>
                    <!-- <input
                    type="date"
                    min="1995"
                    max="2021"
                    id="anneemiseencirculation"
                    class="form-control"
                    v-model="anneemiseencirculation"
                  /> -->
                    <input
                      type="text"
                      class="form-control"
                      v-model="anneemiseencirculation"
                    />
                  </div>
                  <div class="col-md-6">
                    <label>Nombre de place</label>
                    <input
                      type="number"
                      id="nbrePlace"
                      class="form-control"
                      v-model="nbrePlace"
                    />
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-6">
                    <label>Couleur</label>
                    <input
                      type="text"
                      id="couleur"
                      class="form-control"
                      v-model="couleur"
                    />
                  </div>
                  <div class="col-md-6">
                    <label>Lieu</label>
                    <gmap-autocomplete
                      type="text"
                      class="form-control"
                      id=""
                      placeholder="Emplacement véhicule"
                      @place_changed="setPlace"
                      :select-first-on-enter="true"
                    >
                    </gmap-autocomplete>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-6">
                    <label>Immatriculation</label>
                    <input
                      type="text"
                      id="immatriculation"
                      class="form-control"
                      v-model="immatriculation"
                    />
                  </div>
                  <div class="col-md-6">
                    <label>Chauffeur</label>
                    <multiselect
                      v-model="chauffeurSelected"
                      :options="chauffeurs"
                      label="nom"
                      track-by="id"
                    ></multiselect>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-6">
                    <label>Nom de la compagnie d'assurance</label>
                    <input
                      type="text"
                      class="form-control"
                      id="assurance"
                      v-model="assurance"
                    />
                  </div>
                  <div class="col-md-6">
                    <label>Numéro de l'assurance</label>
                    <input
                      type="text"
                      id="numeroAssurance"
                      class="form-control"
                      v-model="numeroAssurance"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <div>
                <label for="statut">Statut du véhicule</label>
                <multiselect
                  v-model="statutSelect"
                  :options="statut"
                ></multiselect>
              </div>
              <div class="row mt-2">
                <div class="col-md-6">
                  <label>Prix/J </label>
                  <input
                    type="number"
                    id="prixJournalier"
                    class="form-control"
                    v-model="prixPublieJournalier"
                  />
                </div>
                <div class="col-md-6">
                  <label>Prix/J Hors Ville</label>
                  <input
                    type="number"
                    id="prixJournalier"
                    class="form-control"
                    v-model="prixPublieJournalierHorsVille"
                  />
                </div>
              </div>
              <div>
                <label for="observations">Observations</label>
                <textarea
                  class="form-control"
                  id="observations"
                  cols="30"
                  rows="3"
                  v-model="observations"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <label>Photo 1: véhicule vue de face</label>
                  <input
                    type="file"
                    id="face"
                    ref="face"
                    v-on:change="handleFilevehiculeFace"
                    class="form-control"
                  />
                </div>
                <div class="col-md-6">
                  <label>Photo 2: véhicule vue d'intérieur</label>
                  <input
                    type="file"
                    ref="interieur"
                    class="form-control"
                    id="interieur"
                    v-on:change="handleFilevehiculeInterieur"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-6">
                  <label>Photo 3: véhicule vue d'intérieur</label>
                  <input
                    type="file"
                    ref="vueInterieur"
                    class="form-control"
                    id="vueInterieur"
                    v-on:change="handleFilevehiculeVue"
                  />
                </div>
                <div class="col-md-6">
                  <label>Photo 4: véhicule vue d'arrière</label>
                  <input
                    type="file"
                    ref="arriere"
                    class="form-control"
                    id="arriere"
                    v-on:change="handleFilevehiculearriere()"
                  />
                </div>
              </div>
              <div class="form-group mt-4">
                <button
                  class="btn btn-primary float-right"
                  @click="addvehicule"
                >
                  Ajouter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
